<template>
  <div id="map" style="z-index: 0">
    <v-card
      id="layermenu"
      width="17vw"
      class="container"
      style="background-color: rgba(255, 255, 255, 0.5)"
      @mouseover="disableMap()"
      @mouseleave="enableMap()"
    >
      <!-- TODO: legend, opacity slider, vector layer -->

      <div class="logos">
        <v-img
          src="https://staticfiles.geoville.com/data/copernicus_lms_logo.png"
        />
      </div>
      <hr />
      <div style="overflow-y: auto; overflow-x: hidden; max-height: 85vh">
        <v-expansion-panels multiple>
          <v-expansion-panel class="layer-panel">
            <v-expansion-panel-header>Base Layer:</v-expansion-panel-header>

            <v-expansion-panel-content fluid>
              <v-row style="margin: 0px">
                <v-col
                  v-for="(element, id) in baseMaps"
                  :key="id"
                  style="max-width: fit-content; padding: 5px"
                >
                  <v-img
                    @click="setBaseMap(id)"
                    :src="element.icon"
                    class="basemap-icon"
                    :style="[
                      element.active
                        ? { border: '3px solid #1e90b6' }
                        : { border: '3px solid #DDDDDD' },
                    ]"
                  />
                </v-col>
              </v-row>
              <v-card
                id="map-attribution"
                v-html="getBaseMapAttribution()"
              ></v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="layer-panel">
            <v-expansion-panel-header>Data Layers:</v-expansion-panel-header>
            <v-expansion-panel-content fluid>
              <v-expansion-panels multiple v-model="expansionPanelItems">
                <v-expansion-panel
                  class="layer-panel"
                  v-for="(element, id) in overlayMaps"
                  :key="id"
                  :disabled="element.disabled"
                  :collapsed="element.disabled"
                >
                  <v-expansion-panel-header
                    :hide-actions="!element.hasOwnProperty('opacity')"
                    :style="[
                      element.disabled
                        ? { 'pointer-events': 'none', padding: '0px' }
                        : { 'pointer-events': 'all', padding: '0px' },
                    ]"
                  >
                    <div style="max-width: 35%; padding-left: 5px">
                      <v-row align="center" justify="center">
                        <v-col cols="2">
                          <v-row>
                            <v-btn
                              icon
                              plain
                              @click="up(id)"
                              @click.native.stop
                              v-if="!top(id)"
                              ><v-icon>mdi-chevron-up</v-icon></v-btn
                            >
                          </v-row>
                          <v-row>
                            <v-btn
                              icon
                              plain
                              @click="down(id)"
                              @click.native.stop
                              v-if="!bottom(id)"
                              ><v-icon>mdi-chevron-down</v-icon></v-btn
                            >
                          </v-row>
                        </v-col>
                        <v-col cols="2">
                          <v-simple-checkbox
                            @input="updateOverlayMaps(id)"
                            :value="element.displayed"
                            style="max-width: fit-content"
                            :id="element.id + 'displayedcheckbox'"
                            :disabled="element.disabled"
                            light
                            :ripple="false"
                          ></v-simple-checkbox>
                        </v-col>
                        <v-col>
                          <v-simple-checkbox
                            @input="getStatistics(id)"
                            :value="element.active"
                            style="max-width: fit-content"
                            :id="element.id + 'checkbox'"
                            :on-icon="'mdi-chart-box'"
                            :off-icon="'mdi-chart-box-outline'"
                            v-if="overlayMaps[id].type != 'vector'"
                            :ripple="false"
                          ></v-simple-checkbox>
                        </v-col>
                      </v-row>
                    </div>
                    <p style="margin-bottom: 2px">{{ element.name }}</p>
                  </v-expansion-panel-header>
                  <p v-if="element.disabled" style="color: #444444">
                    Layer disabled: zoom in to activate
                  </p>
                  <v-expansion-panel-content>
                    <v-slider
                      v-if="element.hasOwnProperty('opacity')"
                      v-model="element.opacity"
                      @change="setOpacity(id)"
                      max="100"
                      min="0"
                      label="Opacity"
                    ></v-slider>
                    <div
                      class="legend"
                      v-if="legendData.hasOwnProperty(id)"
                      style="margin-bottom: 5px"
                    >
                      <span v-html="element.text"></span>
                    </div>
                    <div class="legend" v-if="legendData.hasOwnProperty(id)">
                      <div
                        class="legend-padding-items"
                        v-for="(item, key) in legendData[id]"
                        :key="key"
                      >
                        <i
                          v-if="item.color"
                          class="colorcircle"
                          :style="{ background: item.color }"
                        ></i
                        >{{ item.name }}<br />
                      </div>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                
              </v-expansion-panels>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </v-card>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet.vectorgrid";
import "leaflet-spin";
import "leaflet.gridlayer.googlemutant";
import "leaflet-bing-layer";
import "../plugins/leaflet-tilelayer-here.js";

export default {
  name: "Map",
  props: {
    bounds: { type: Array },
    nuts_id: { type: String },
    searchFlag: { type: Boolean },
    legendData: { type: Object },
    invalidateSize: { type: String },
  },
  data() {
    return {
      map: null,
      tileLayer: null,
      sidebarOpened: true,
      mapConfiguration: {
        center: [55.54, 9.5],
        zoom: 4,
        maxZoom: 18,
      },
      expansionPanelItems: [],
      baseMaps: {
        osm: {
          type: "tileLayer",
          url: "https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager/{z}/{x}/{y}.png",
          active: true,
          attribution:
            '&copy; <a href="http://www.openstreetmap.org/copyright" target="blank">OpenStreetMap</a>',
          icon: "https://staticfiles.geoville.com/data/clcplus-backbone/osm-logo.png",
          mapObject: null,
        },
        "esri-sat": {
          type: "tileLayer",
          url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
          active: false,
          attribution:
            "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community",
          icon: "https://staticfiles.geoville.com/data/clcplus-backbone/esri-logo.png",
          mapObject: null,
        },
        "eox-sat": {
          type: "tileLayer",
          url: "https://s2maps-tiles.eu/wmts?layer=s2cloudless-2020_3857&style=default&tilematrixset=GoogleMapsCompatible&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image%2Fjpeg&TileMatrix={z}&TileCol={x}&TileRow={y}",
          active: false,
          attribution:
            '<a href="https://s2maps.eu" target="blank">Sentinel-2 cloudless</a> - by <a href="https://eox.at/" target="blank">EOX IT Services GmbH</a> (Contains modified Copernicus Sentinel data 2020)',
          icon: "https://staticfiles.geoville.com/data/clcplus-backbone/eox-logo.png",
          mapObject: null,
        },
        "google-sat": {
          type: "googlemutant",
          active: false,
          attribution:
            'Tiles &copy; Google (<a href="https://www.google.com/intl/en-US_US/help/terms_maps.html" target="_blank">Terms of Use</a>); Imagery &copy;2021 NASA, TerraMetrics',
          icon: "https://staticfiles.geoville.com/data/clcplus-backbone/google-logo.png",
          mapObject: null,
          apikey: "AIzaSyATtn7eHeAL8M3FD2jCd0_JQmPPOOnUFpg",
        },
        "bing-sat": {
          type: "bing",
          active: false,
          attribution:
            "&copy; 2021 Microsoft Corporation, &copy; 2021 Maxar, &copy; CNES (2021) Distribution Airbus DS",
          icon: "https://staticfiles.geoville.com/data/clcplus-backbone/bing-logo.png",
          mapObject: null,
          bingKey:
            "AjU2b-0kG-mPyaBkuT85URKHE9UbksBsK5Yo_EBqW3VkIWI4HhPTWl_Ze24_W4Jn",
        },
      },
      overlayMaps: {
                nuts0: {
          url: "https://tileserver.clcplus-backbone.geoville.com/clc-bb-ui/nuts0_0-14/{z}/{x}/{y}.pbf",
          type: "vector",
          name: "NUTS-Level0",
          zLevel: 310,
          displayed: false,
          mapObject: null,
          uniqueIdKey: "NUTS_ID",
          product: "NUTS",
          readyForReport: false,
        },
        nuts1: {
          url: "https://tileserver.clcplus-backbone.geoville.com/clc-bb-ui/nuts1_0-14/{z}/{x}/{y}.pbf",
          type: "vector",
          name: "NUTS-Level1",
          zLevel: 309,
          displayed: false,
          mapObject: null,
          uniqueIdKey: "NUTS_ID",
          product: "NUTS",
          readyForReport: false,
        },
        nuts2: {
          url: "https://tileserver.clcplus-backbone.geoville.com/clc-bb-ui/nuts2_0-14/{z}/{x}/{y}.pbf",
          type: "vector",
          name: "NUTS-Level2",
          zLevel: 308,
          displayed: false,
          mapObject: null,
          uniqueIdKey: "NUTS_ID",
          product: "NUTS",
          readyForReport: false,
        },
        nuts3: {
          url: "https://tileserver.clcplus-backbone.geoville.com/clc-bb-ui/nuts3_0-14/{z}/{x}/{y}.pbf",
          type: "vector",
          name: "NUTS-Level3",
          zLevel: 307,
          displayed: false,
          mapObject: null,
          uniqueIdKey: "NUTS_ID",
          product: "NUTS",
          readyForReport: false,
        },
        raster: {
          url: "https://tileserver.clcplus-backbone.geoville.com/clc-bb-ui/CLMS_CLCplus_RASTER_2018_010m_EU_03035_V0_9_eu39/{z}/{x}/{y}.png",
          type: "raster",
          name: "BB-Raster 2018",
          zLevel: 306,
          displayed: false,
          active: true,
          mapObject: null,
          opacity: 100,
          readyForReport: false,
          product: "Backbone",
          route: "raster",
          segment: null,
          text: "<p>11-class Raster Layer in a 10x10m resolution. <br/><br/>Reference Year: 2018</p>",
        },
        vectorproduct: {
          url: "https://geoserver.clcplus-backbone.geoville.com/geoserver/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&LAYER=backbone:vectorproduct&STYLE=&TILEMATRIX=EPSG:3857:{z}&TILEMATRIXSET=EPSG:3857&FORMAT=application/vnd.mapbox-vector-tile&TILECOL={x}&TILEROW={y}",
          type: "vector",
          name: "BB-Vector 2018",
          zLevel: 305,
          displayed: false,
          active: false,
          mapObject: null,
          uniqueIdKey: "uid",
          opacity: 100,
          disabled: true,
          readyForReport: false,
          product: "Backbone",
          route: "raster",
          segment: "vector",
          text:
            "<p>18-class Vector Layer with 0.5 ha minimum mapping unit, enriched with additional EO and CLMS attributes." +
            "Both Layers are fully compliant with the EAGLE data model & nomenclature. <br/><br/>Reference Year: 2018</p>",
        },
        natura2k_vector: {
          url: "https://geoserver.clcplus-backbone.geoville.com/geoserver/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&LAYER=backbone:clcbb_natura2000_frontend&STYLE=&TILEMATRIX=EPSG:3857:{z}&TILEMATRIXSET=EPSG:3857&FORMAT=application/vnd.mapbox-vector-tile&TILECOL={x}&TILEROW={y}",
          type: "vector",
          name: "N2K-Vector 2018",
          zLevel: 304,
          displayed: false,
          active: false,
          mapObject: null,
          uniqueIdKey: "UID",
          opacity: 100,
          disabled: true,
          readyForReport: false,
          product: "Natura2000",
          route: null,
          segment: "natura2000",
          text: "<p>Need Information - WIP <br/><br/>Reference Year: 2018</p>",
        },
        water_wetness: {
          url: "https://tileserver.clcplus-backbone.geoville.com/WAW_2018_010m_eu_03035_V2_0/{z}/{x}/{y}.png",
          type: "raster",
          name: "Water-Wetness 2018",
          zLevel: 303,
          displayed: false,
          active: false,
          mapObject: null,
          opacity: 100,
          readyForReport: false,
          product: "Water Wetness",
          route: "water_wetness",
          segment: null,
          text: "<p>Water Wetness Layer in a 10x10m resolution. <br/><br/>Reference Year: 2018</p>",
        },
        imd_raster: {
          url: "https://tileserver.clcplus-backbone.geoville.com/IMD_2018_010m_eu_03035_V2_0/{z}/{x}/{y}.png",
          type: "raster",
          name: "IMD 2018",
          zLevel: 302,
          displayed: false,
          active: false,
          mapObject: null,
          opacity: 100,
          readyForReport: false,
          product: "Imperviousness",
          route: "imperviousness",
          segment: null,
          text: "<p>Imperviousness Layer in a 10x10m resolution. <br/><br/>Reference Year: 2018</p>",
        },
      },
      vectorStyles: {
        vectorproduct: function (p) {
          var fColor = "#FF0000";
          var fOpacity = 1;
          if (p.class_code == 11) fColor = "#E6004D";
          else if (p.class_code == 12) fColor = "#FF0000";
          else if (p.class_code == 21) fColor = "#00A650";
          else if (p.class_code == 22) fColor = "#00C150";
          else if (p.class_code == 31) fColor = "#6DD400";
          else if (p.class_code == 32) fColor = "#4F9A00";
          else if (p.class_code == 33) fColor = "#80FF00";
          else if (p.class_code == 40) fColor = "#A6F200";
          else if (p.class_code == 51) fColor = "#BABB4D";
          else if (p.class_code == 52) fColor = "#D3D44D";
          else if (p.class_code == 53) fColor = "#E6E64D";
          else if (p.class_code == 60) fColor = "#FFFFA8";
          else if (p.class_code == 70) fColor = "#A6A6FF";
          else if (p.class_code == 81) fColor = "#CCFFCC";
          else if (p.class_code == 82) fColor = "#93FF93";
          else if (p.class_code == 90) fColor = "#CCCCCC";
          else if (p.class_code == 100) fColor = "#80F2E6";
          else if (p.class_code == 110) fColor = "#A6E6CC";
          else fOpacity = 0;
          var style = {
            color: "#000000",
            weight: 0.5,
            fill: true,
            fillColor: fColor,
            fillOpacity: fOpacity,
          };
          return style;
        },
        clcbb_natura2000_frontend: function (p) {
          var fColor;
          var fOpacity = 1;
          if (p.class_code == 1110) fColor = "#c00000";
          else if (p.class_code == 1120) fColor = "#cc4df2";
          else if (p.class_code == 1210) fColor = "#969696";
          else if (p.class_code == 1220) fColor = "#595959";
          else if (p.class_code == 1230) fColor = "#e6cccc";
          else if (p.class_code == 1240) fColor = "#e6cce6";
          else if (p.class_code == 1310) fColor = "#734d37";
          else if (p.class_code == 1320) fColor = "#874545";
          else if (p.class_code == 1400) fColor = "#8cdc00";
          else if (p.class_code == 2110) fColor = "#ffffa8";
          else if (p.class_code == 2120) fColor = "#bebe64";
          else if (p.class_code == 2210) fColor = "#e07d01";
          else if (p.class_code == 2220) fColor = "#df9f00";
          else if (p.class_code == 2310) fColor = "#ffe6a6";
          else if (p.class_code == 2320) fColor = "#ffe64d";
          else if (p.class_code == 2330) fColor = "#e6cc4d";
          else if (p.class_code == 2340) fColor = "#edc69f";
          else if (p.class_code == 3110) fColor = "#96cd00";
          else if (p.class_code == 3120) fColor = "#82c800";
          else if (p.class_code == 3210) fColor = "#007300";
          else if (p.class_code == 3220) fColor = "#006400";
          else if (p.class_code == 3310) fColor = "#41af00";
          else if (p.class_code == 3320) fColor = "#007d00";
          else if (p.class_code == 3400) fColor = "#a6f200";
          else if (p.class_code == 3500) fColor = "#c3f200";
          else if (p.class_code == 3600) fColor = "#003c00";
          else if (p.class_code == 4110) fColor = "#e6e64d";
          else if (p.class_code == 4211) fColor = "#e0f24d";
          else if (p.class_code == 4212) fColor = "#e0e84d";
          else if (p.class_code == 4220) fColor = "#ccf24d";
          else if (p.class_code == 5100) fColor = "#a6ff80";
          else if (p.class_code == 5200) fColor = "#96eb76";
          else if (p.class_code == 5300) fColor = "#a6e64d";
          else if (p.class_code == 6100) fColor = "#ccffcc";
          else if (p.class_code == 6210) fColor = "#dcf5dc";
          else if (p.class_code == 6220) fColor = "#f0f5dc";
          else if (p.class_code == 6310) fColor = "#c8d2c8";
          else if (p.class_code == 6320) fColor = "#b4beb4";
          else if (p.class_code == 6330) fColor = "#a0aaa0";
          else if (p.class_code == 7110) fColor = "#a6a6ff";
          else if (p.class_code == 7121) fColor = "#4d4dff";
          else if (p.class_code == 7122) fColor = "#646ee6";
          else if (p.class_code == 7210) fColor = "#91c8f0";
          else if (p.class_code == 7220) fColor = "#64a0c8";
          else if (p.class_code == 7230) fColor = "#a6a6e6";
          else if (p.class_code == 8110) fColor = "#00ccf0";
          else if (p.class_code == 8120) fColor = "#00ccc8";
          else if (p.class_code == 8130) fColor = "#41dceb";
          else if (p.class_code == 8210) fColor = "#80f2e6";
          else if (p.class_code == 8220) fColor = "#80f5f0";
          else if (p.class_code == 8230) fColor = "#80defa";
          else if (p.class_code == 8240) fColor = "#7dfafa";
          else if (p.class_code == 8310) fColor = "#00ffa6";
          else if (p.class_code == 8320) fColor = "#00ff87";
          else if (p.class_code == 8330) fColor = "#00c878";
          else if (p.class_code == 8410) fColor = "#e6f2ff";
          else if (p.class_code == 8420) fColor = "#d2e6eb";
          else fOpacity = 0;
          var style = {
            color: "#000000",
            weight: 0.5,
            fill: true,
            fillColor: fColor,
            fillOpacity: fOpacity,
          };
          return style;
        },
        nuts0: this.getNutsStyle(),
        nuts1: this.getNutsStyle(),
        nuts2: this.getNutsStyle(),
        nuts3: this.getNutsStyle(),
      },
      highlightedFeature: { layerId: null, featureID: null, classCode: 0 },
      highlightedFeatureHover: { layerId: null, featureID: null },
      visibleLayer: null,
    };
  },
  computed: {
    products() {
      var dict = {};
      for (var item in this.overlayMaps) {
        if (this.overlayMaps[item].product != "NUTS") {
          dict[item] = this.overlayMaps[item];
        }
      }
      return dict;
    },
    nuts() {
      var dict = {};
      for (var item in this.overlayMaps) {
        if (this.overlayMaps[item].product == "NUTS") {
          dict[item] = this.overlayMaps[item];
        }
      }
      return dict;
    },
  },
  mounted() {
    // adding google maps js api
    var e = document.createElement("script");
    var t =
      "https://maps.googleapis.com/maps/api/js?key=" +
      this.baseMaps["google-sat"].apikey;
    e.setAttribute("src", t);
    document.head.appendChild(e);

    this.initMap();
  },
  methods: {
    /**
     * This function initializes the leaflte map
     */
    initMap: function () {
      this.map = L.map("map").setView(
        this.mapConfiguration.center,
        this.mapConfiguration.zoom
      );

      this.map.on("zoomend", this.zoomLevelUpdated);

      // Add base layers, overlay layers and layers control

      // Basemap(s)
      this.map.createPane("basemap");
      this.map.getPane("basemap").style.zIndex = 100;
      this.initBaseMaps();

      // Overlay layers
      this.setOverlayLayers(this.map);
      this.updateOverlayMaps("raster");
      this.$emit(
                  "clickOnNuts",
                  "DK",
                  "raster",
                );

      var scale = L.control.scale({
        metric: true,
        imperial: false,
        maxWidth: 300,
        position: "bottomright",
      });
      scale.addTo(this.map);
    },

    disableMap() {
      this.map.dragging.disable();
      this.map.doubleClickZoom.disable();
      this.map.scrollWheelZoom.disable();
    },

    enableMap() {
      this.map.dragging.enable();
      this.map.doubleClickZoom.enable();
      this.map.scrollWheelZoom.enable();
    },

    zoomLevelUpdated() {
      if (this.map.getZoom() > 12) {
        this.overlayMaps.vectorproduct.disabled = false;
        this.overlayMaps.natura2k_vector.disabled = false;
      } else {
        this.overlayMaps.vectorproduct.disabled = true;
        this.overlayMaps.natura2k_vector.disabled = true;
        var index = this.expansionPanelItems.indexOf(
          Object.keys(this.overlayMaps).indexOf("vectorproduct")
        );
        if (index > -1) {
          this.expansionPanelItems.splice(index, 1);
        }
        index = this.expansionPanelItems.indexOf(
          Object.keys(this.overlayMaps).indexOf("natura2k_vector")
        );
        if (index > -1) {
          this.expansionPanelItems.splice(index, 1);
        }
      }
    },
    udpatePaneZLevels() {
      for (var item in this.overlayMaps) {
        this.map.getPane(item).style.zIndex = this.overlayMaps[item].zLevel;
      }
    },
    setOverlayLayers(map) {
      var ref = this;
      for (var key in this.overlayMaps) {
        const layerId = key;
        const element = this.overlayMaps[layerId];

        // create panes for z-level ordering of overlay layers
        map.createPane(layerId);
        map.getPane(layerId).style.zIndex = element.zLevel;

        // create overlay map layers
        if (element.type == "vector") {
          element.mapObject = new L.vectorGrid.protobuf(
            element.url,
            ref.getVectorTileLayerOptions(layerId)
          )
            .on("mouseover", function (e) {
              if (e.layer.properties.class_code) {
                ref.setHighlightHover(
                  layerId,
                  e.layer.properties[element.uniqueIdKey],
                  e.layer.properties.class_code
                );
              } else {
                ref.setHighlightHover(
                  layerId,
                  e.layer.properties[element.uniqueIdKey]
                );
              }
            })
            .on("mouseout", function () {
              ref.resetHighlightHover();
            })
            .on("click", function (e) {
              var route = null;
              for (var item in ref.products) {
                if (ref.products[item].active) {
                  route = ref.products[item].route;
                }
              }
              if (e.layer.properties.class_code) {
                var name = null;
                if (ref.overlayMaps.vectorproduct.displayed ) {
                  if(ref.overlayMaps.natura2k_vector.zLevel > ref.overlayMaps.vectorproduct.zLevel && ref.overlayMaps.natura2k_vector.displayed) {
                    name = "natura2k_vector";
                  }
                  else {
                    name = "vectorproduct"
                  }
                } else if (ref.overlayMaps.natura2k_vector.displayed) {
                  name = "natura2k_vector";
                }

                ref.$emit(
                  "clickOnSegment",
                  e.layer.properties[element.uniqueIdKey],
                  name,
                  name
                );
                ref.setHighlight(
                  name,
                  e.layer.properties[element.uniqueIdKey],
                  e.layer.properties.class_code
                );
              } else {
                ref.$emit(
                  "clickOnNuts",
                  e.layer.properties[element.uniqueIdKey],
                  route
                );
              }
            })
            .on("loading", function () {
              ref.map.spin(true);
              element.readyForReport = false;
            })
            .on("load", function () {
              ref.map.spin(false);
              element.readyForReport = true;
            });
        } else if (element.type == "raster") {
          element.mapObject = L.tileLayer(element.url, {
            attribution: "© by GeoVille",
            minNativeZoom: 1,
            maxNativeZoom: 14,
            pane: layerId,
          })
            .on("loading", function () {
              ref.map.spin(true);
              element.readyForReport = false;
            })
            .on("load", function () {
              ref.map.spin(false);
              element.readyForReport = true;
            });
        }
      }
    },

    setVisibleLayer(layerId) {
      if (this.searchFlag) {
        for (var key in this.overlayMaps) {
          if (key.startsWith("nuts")) {
            this.map.removeLayer(this.overlayMaps[key].mapObject);
            this.overlayMaps[key].displayed = false;
          }
        }
      }
      this.visibleLayer = layerId;
      this.overlayMaps[layerId].mapObject.addTo(this.map);
      this.overlayMaps[layerId].displayed = true;
    },

    getVectorTileLayerOptions(layerId) {
      // specifying the vector tile layer options
      var ref = this;

      var options = {
        rendererFactory: L.canvas.tile,
        vectorTileLayerStyles: ref.vectorStyles,
        interactive: true,
        getFeatureId: function (f) {
          return f.properties[ref.overlayMaps[layerId].uniqueIdKey];
        },
        pane: layerId,
      };
      if (layerId == "vectorproduct" || layerId == "natura2k_vector") {
        options["maxNativeZoom"] = ref.mapConfiguration.maxZoom;
        options["minZoom"] = 13;
      } else {
        options["maxNativeZoom"] = 14;
      }
      return options;
    },

    //Sets highlight for nuts-region
    setHighlight(layerId, id, classCode = 0) {
      this.resetHighlight();
      if (id) {
        // set style of hilighted features
        var style = {
          color: "#000000",
          weight: 5,
        };
        var fillData = null;
        if (layerId == "vectorproduct") {
          fillData = this.getVectorproductFeatureColor(classCode);
          style = {
            color: "#000000",
            weight: 3,
            fill: true,
            fillColor: fillData.fColor,
            fillOpacity: fillData.fOpacity,
          };
        } else if (layerId == "natura2k_vector") {
          fillData = this.getNatura2kvectorFeatureColor(classCode);
          style = {
            color: "#000000",
            weight: 3,
            fill: true,
            fillColor: fillData.fColor,
            fillOpacity: fillData.fOpacity,
          };
        }
        this.overlayMaps[layerId].mapObject.setFeatureStyle(id, style);
        this.highlightedFeature = {
          layerId: layerId,
          featureID: id,
          classCode: classCode,
        };
      }
    },

    setHighlightHover(layerId, id, classCode = 0) {
      var style = {
        color: "#000000",
        weight: 3,
      };
      var fillData = null;
      if (layerId == "vectorproduct") {
        fillData = this.getVectorproductFeatureColor(classCode);
        style["fill"] = true;
        style["fillColor"] = fillData.fColor;
        style["fillOpacity"] = fillData.fOpacity;
      } else if (layerId == "natura2k_vector") {
        fillData = this.getNatura2kvectorFeatureColor(classCode);
        style["fill"] = true;
        style["fillColor"] = fillData.fColor;
        style["fillOpacity"] = fillData.fOpacity;
      }
      if (this.highlightedFeature.featureID == id) {
        style["weight"] = 3;
      }
      if (id) {
        // set style of hilighted features
        this.overlayMaps[layerId].mapObject.setFeatureStyle(id, style);
        this.highlightedFeatureHover = {
          layerId: layerId,
          featureID: id,
        };
      }
    },

    resetHighlight() {
      if (
        this.highlightedFeature.layerId != null &&
        this.highlightedFeature.featureID
      ) {
        this.overlayMaps[
          this.highlightedFeature.layerId
        ].mapObject.resetFeatureStyle(this.highlightedFeature.featureID);
      }
      this.highlightedFeature = {
        layerId: null,
        featureID: null,
        classCode: 0,
      };
    },

    resetHighlightHover() {
      if (
        this.highlightedFeatureHover.layerId != null &&
        this.highlightedFeatureHover.featureID
      ) {
        this.overlayMaps[
          this.highlightedFeatureHover.layerId
        ].mapObject.resetFeatureStyle(this.highlightedFeatureHover.featureID);
      }
      if (
        this.highlightedFeature.featureID ==
        this.highlightedFeatureHover.featureID
      ) {
        this.setHighlight(
          this.highlightedFeature.layerId,
          this.highlightedFeature.featureID,
          this.highlightedFeature.classCode
        );
      }
      this.highlightedFeatureHover = { layerId: null, featureID: null };
    },

    getNutsStyle() {
      return {
        fill: false,
        color: "#000000",
        weight: 1,
      };
    },

    getVectorproductFeatureColor(classCode) {
      var fColor;
      var fOpacity = 1;
      if (classCode == 11) fColor = "#E6004D";
      else if (classCode == 12) fColor = "#FF0000";
      else if (classCode == 21) fColor = "#00A650";
      else if (classCode == 22) fColor = "#00C150";
      else if (classCode == 31) fColor = "#6DD400";
      else if (classCode == 32) fColor = "#4F9A00";
      else if (classCode == 33) fColor = "#80FF00";
      else if (classCode == 40) fColor = "#A6F200";
      else if (classCode == 51) fColor = "#BABB4D";
      else if (classCode == 52) fColor = "#D3D44D";
      else if (classCode == 53) fColor = "#E6E64D";
      else if (classCode == 60) fColor = "#FFFFA8";
      else if (classCode == 70) fColor = "#A6A6FF";
      else if (classCode == 81) fColor = "#CCFFCC";
      else if (classCode == 82) fColor = "#93FF93";
      else if (classCode == 90) fColor = "#CCCCCC";
      else if (classCode == 100) fColor = "#80F2E6";
      else if (classCode == 110) fColor = "#A6E6CC";
      else fOpacity = 0;
      return { fColor: fColor, fOpacity: fOpacity };
    },

    getNatura2kvectorFeatureColor(classCode) {
      var fColor;
      var fOpacity = 1;
      if (classCode > 5000) fColor = "#E6004D";
      else if (classCode <= 5000) fColor = "#FFFFA8";
      else fOpacity = 0;
      return { fColor: fColor, fOpacity: fOpacity };
    },

    invalidateMapSize() {
      this.map.invalidateSize();
    },
    updateBounds() {
      this.map.invalidateSize();
      var bounds = new L.LatLngBounds(this.bounds);
      this.map.fitBounds(bounds);
    },
    initBaseMaps() {
      for (var key in this.baseMaps) {
        if (this.baseMaps[key].type == "tileLayer") {
          this.baseMaps[key].mapObject = L.tileLayer(this.baseMaps[key].url, {
            maxZoom: this.mapConfiguration.maxZoom,
            attribution: this.baseMaps[key].attribution,
            pane: "basemap",
          });
        } else if (this.baseMaps[key].type == "googlemutant") {
          this.baseMaps[key].mapObject = L.gridLayer.googleMutant({
            type: "satellite",
            pane: "basemap",
          });
        } else if (this.baseMaps[key].type == "here") {
          this.baseMaps[key].mapObject = L.tileLayer.here({
            appId: this.baseMaps[key].appId,
            appCode: this.baseMaps[key].appCode,
            scheme: this.baseMaps[key].scheme,
          });
        } else if (this.baseMaps[key].type == "bing") {
          this.baseMaps[key].mapObject = L.tileLayer.bing(
            this.baseMaps[key].bingKey
          );
        }
        if (this.baseMaps[key].active)
          this.baseMaps[key].mapObject.addTo(this.map);
      }
    },
    setBaseMap(mapId) {
      for (var key in this.baseMaps) {
        if (this.baseMaps[key].active) {
          this.map.removeLayer(this.baseMaps[key].mapObject);
          this.baseMaps[key].active = false;
        }
      }
      this.baseMaps[mapId].mapObject.addTo(this.map);
      this.baseMaps[mapId].active = true;
    },
    getBaseMapAttribution() {
      for (var key in this.baseMaps) {
        if (this.baseMaps[key].active) {
          return this.baseMaps[key].attribution + "<br>Projection: EPSG 3857";
        }
      }
      return "";
    },
    updateOverlayMaps(id) {
      for (var key in this.overlayMaps) {
        if (key == id) {
          var item = this.overlayMaps[key];
          if (item.displayed) {
            this.map.removeLayer(item.mapObject);
            item.displayed = false;
            this.map.spin(false);
          } else {
            item.mapObject.addTo(this.map);
            item.displayed = true;
          }
        }
      }
      this.udpatePaneZLevels();
    },
    getStatistics(id) {
      if(this.overlayMaps[id].active) {
            return;
      }
      for (var key in this.overlayMaps) {
        if (key == id) {
          var item = this.overlayMaps[key];
          item.active = true;
          if (item.product != "NUTS") {
            for (var element in this.products) {
              if (
                this.overlayMaps[element].active &&
                element != key &&
                this.overlayMaps[element].product != item.product
              ) {
                this.overlayMaps[element].active = false;
              }
            }
          }
          this.$emit("clickOnLayer", this.overlayMaps[id]["route"]);
        }
      }
    },
    setOpacity(id) {
      this.overlayMaps[id].mapObject.setOpacity(
        this.overlayMaps[id].opacity / 100
      );
    },
    /* eslint-disable */
    until(conditionFunction) {
      const poll = (resolve) => {
        if (conditionFunction()) resolve();
        else setTimeout((_) => poll(resolve), 400);
      };
      return new Promise(poll);
    },
    async zoomToBounds() {
      this.map.invalidateSize();
      this.map.fitBounds(this.bounds);
      for (var element in this.overlayMaps) {
        var item = this.overlayMaps[element];
        if (
          (item.hasOwnProperty("disabled") &&
            !item.disabled &&
            item.displayed) ||
          (!item.hasOwnProperty("disabled") && item.displayed)
        ) {
          var x = await this.until((_) => item.readyForReport === true);
        }
      }
      return x;
    },
    /* eslint-enable */
    up(id) {
      var index = Object.keys(this.overlayMaps).indexOf(id);
      var swap_id = Object.keys(this.overlayMaps)[index - 1];
      var dict_tmp = {};
      var tmp_swap = {};
      for (var i = 0; i < Object.keys(this.overlayMaps).length; i++) {
        if (index - 1 == i) {
          for (var x in Object.keys(Object.values(this.overlayMaps)[i])) {
            tmp_swap[Object.keys(Object.values(this.overlayMaps)[i])[x]] =
              Object.values(this.overlayMaps)[i][
                Object.keys(Object.values(this.overlayMaps)[i])[x]
              ];
          }
        } else if (index == i) {
          const value = {};
          for (x in Object.keys(Object.values(this.overlayMaps)[i])) {
            if (
              Object.keys(Object.values(this.overlayMaps)[i])[x] == "zLevel"
            ) {
              value[Object.keys(Object.values(this.overlayMaps)[i])[x]] =
                tmp_swap["zLevel"];
              tmp_swap["zLevel"] = Object.values(this.overlayMaps)[i][
                Object.keys(Object.values(this.overlayMaps)[i])[x]
              ];
            } else {
              value[Object.keys(Object.values(this.overlayMaps)[i])[x]] =
                Object.values(this.overlayMaps)[i][
                  Object.keys(Object.values(this.overlayMaps)[i])[x]
                ];
            }
          }
          dict_tmp[id] = value;
          dict_tmp[swap_id] = tmp_swap;
        } else {
          var key = JSON.parse(
            JSON.stringify(Object.keys(this.overlayMaps)[i])
          );
          const value = {};
          for (x in Object.keys(Object.values(this.overlayMaps)[i])) {
            value[Object.keys(Object.values(this.overlayMaps)[i])[x]] =
              Object.values(this.overlayMaps)[i][
                Object.keys(Object.values(this.overlayMaps)[i])[x]
              ];
          }
          dict_tmp[key] = value;
        }
      }
      this.overlayMaps = dict_tmp;
      this.udpatePaneZLevels();
    },
    down(id) {
      var index = Object.keys(this.overlayMaps).indexOf(id);
      var swap_id = Object.keys(this.overlayMaps)[index + 1];
      var dict_tmp = {};
      var tmp_swap = {};
      for (var i = 0; i < Object.keys(this.overlayMaps).length; i++) {
        if (index + 1 == i) {
          const value = {};
          for (x in Object.keys(Object.values(this.overlayMaps)[i])) {
            if (
              Object.keys(Object.values(this.overlayMaps)[i])[x] == "zLevel"
            ) {
              value[Object.keys(Object.values(this.overlayMaps)[i])[x]] =
                tmp_swap["zLevel"];
              tmp_swap["zLevel"] = Object.values(this.overlayMaps)[i][
                Object.keys(Object.values(this.overlayMaps)[i])[x]
              ];
            } else {
              value[Object.keys(Object.values(this.overlayMaps)[i])[x]] =
                Object.values(this.overlayMaps)[i][
                  Object.keys(Object.values(this.overlayMaps)[i])[x]
                ];
            }
          }
          dict_tmp[swap_id] = value;
          dict_tmp[id] = tmp_swap;
        } else if (index == i) {
          for (var x in Object.keys(Object.values(this.overlayMaps)[i])) {
            tmp_swap[Object.keys(Object.values(this.overlayMaps)[i])[x]] =
              Object.values(this.overlayMaps)[i][
                Object.keys(Object.values(this.overlayMaps)[i])[x]
              ];
          }
        } else {
          var key = JSON.parse(
            JSON.stringify(Object.keys(this.overlayMaps)[i])
          );
          const value = {};
          for (x in Object.keys(Object.values(this.overlayMaps)[i])) {
            value[Object.keys(Object.values(this.overlayMaps)[i])[x]] =
              Object.values(this.overlayMaps)[i][
                Object.keys(Object.values(this.overlayMaps)[i])[x]
              ];
          }
          dict_tmp[key] = value;
        }
      }
      this.overlayMaps = dict_tmp;
      this.udpatePaneZLevels();
    },
    top(id) {
      if (Object.keys(this.overlayMaps).indexOf(id) == 0) {
        return true;
      }
      return false;
    },
    bottom(id) {
      if (
        Object.keys(this.overlayMaps).indexOf(id) ==
        Object.keys(this.overlayMaps).length - 1
      ) {
        return true;
      }
      return false;
    },
  },

  watch: {
    bounds: function (value) {
      this.map.invalidateSize();
      var bounds = new L.LatLngBounds(value);
      this.map.fitBounds(bounds);
    },
    nuts_id: function (value) {
      if (value) {
        this.setVisibleLayer("nuts" + (value.length - 2));
        this.setHighlight("nuts" + (value.length - 2), value);
      }
    },
  },
};
</script>

<style scoped>
#map {
  width: auto;
  height: 100%;
}
</style>

<style>
.layer-panel .v-expansion-panel-content__wrap {
  padding: 3px !important;
}

.legend {
  text-align: left;
  line-height: 18px;
  color: #555;
  padding: 6px 8px;
  background: white;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  width: 100%;
}

.legend i {
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 8px;
  opacity: 0.7;
}

.legend .colorcircle {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  opacity: 1;
  border: 1px solid;
  border-color: #888888;
}

.legend-padding-header {
  padding-bottom: 8px;
}

.legend-padding-items {
  padding-bottom: 2px;
}

.logos {
  padding: 5px;
  background: white;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.logos img {
  width: 290px;
}

/* Leaflet crispness override */
.leaflet-container .leaflet-overlay-pane svg,
.leaflet-container .leaflet-marker-pane img,
.leaflet-container .leaflet-shadow-pane img,
.leaflet-container .leaflet-tile-pane img,
.leaflet-container .leaflet-raster-pane img,
.leaflet-container .leaflet-water_wetness-pane img,
.leaflet-container .leaflet-imd_raster-pane img,
.leaflet-container .img.leaflet-image-layer {
  max-width: none !important;

  /* Preserve crisp pixels with scaled up images */
  image-rendering: optimizeSpeed; /* Legal fallback */
  image-rendering: -moz-crisp-edges; /* Firefox        */
  image-rendering: -o-crisp-edges; /* Opera          */
  image-rendering: -webkit-optimize-contrast; /* Safari         */
  image-rendering: optimize-contrast; /* CSS3 Proposed  */
  image-rendering: crisp-edges; /* CSS4 Proposed  */
  image-rendering: pixelated; /* CSS4 Proposed  */
  -ms-interpolation-mode: nearest-neighbor; /* IE8+           */
}

#layermenu {
  position: absolute;
  z-index: 1500;
  right: 0;
  display: block;
}

.basemap-icon {
  border-radius: 50% !important;
  display: inline-block;
  width: 50px;
  height: 50px;
  background: white;
}

.basemap-icon:hover {
  cursor: pointer;
}

#map-attribution {
  width: 100%;
  padding: 5px;
  margin-bottom: 5px;
}
</style>
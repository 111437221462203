import axios from 'axios'

const axios_services = axios.create({
    baseURL: "https://api.clcplus-backbone.geoville.com/v1/",
})

axios_services.interceptors.request.use(function (config) {
    let key = '6mqsU)4g~Z;xGsA>';
    config.headers.Authorization = key;
    return config;
}, function (error) {
    return Promise.reject(error);
});

export default axios_services
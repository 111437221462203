<template>
  <v-app>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          PROTOTYPE DISCLAIMER
        </v-card-title>

        <v-card-text>
          The demo version is for evaluation purpose only. All results are based
          on provisionally data and are subject to a constant process of further
          development.
          <br />
          Status December 2021:
          <br />
          - Raster Product (2018) complete for EEA39
          <br />
          - Vector Product (2018) for the Regions colored in dark green (see
          image below extent in green):

          <v-img
            src="https://staticfiles.geoville.com/data/clcplus-backbone/vectordemo_extent2.png"
            style="border: black; border-style: groove"
          ></v-img>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Ok </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="reportDialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Generating Report
        </v-card-title>
        <div class="text-center" style="padding: 10px">
          <v-progress-circular indeterminate></v-progress-circular>
        </div>
      </v-card>
    </v-dialog>

    <v-navigation-drawer v-model="drawer" :mini-variant="mini" app left>
      <v-list-item link>
        <v-list-item-icon @click="logout()">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-logout</v-icon>
            </template>
            <span>Logout</span>
          </v-tooltip>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>"My Account"</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item link>
          <v-list-item-icon @click="clickDrawer(1)">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">mdi-magnify</v-icon>
              </template>
              <span>Search</span>
            </v-tooltip>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Search</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="(active[0] && Object.keys(active[0]).length != 0) || segmentID"
          link
        >
          <v-list-item-icon @click="clickDrawer(2)">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">mdi-chart-areaspline</v-icon>
              </template>
              <span>Statistics</span>
            </v-tooltip>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Statistics</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-navigation-drawer
        app
        width="20%"
        id="nav2"
        style="z-index: 1; left: 56px"
        v-show="drawer2 != 0"
      >
        <div v-show="drawer2 == 1">
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-magnify</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Search for Area</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-autocomplete
            auto-select-first
            clearable
            solo
            :items="search_json"
            v-model="search"
            item-text="name"
            return-object
            label="Search for an area here..."
          ></v-autocomplete>
          <v-treeview
            activatable
            :active.sync="active"
            :items="nuts"
            :search="search_string"
            transition
            :open.sync="open"
            @update:active="getActiveValue"
            @update:open="getOpenValue"
            return-object
          ></v-treeview>
          <v-divider></v-divider>
        </div>

        <div v-show="drawer2 == 2">
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-chart-areaspline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Statistics</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <div v-if="active[0] && Object.keys(active[0]).length != 0">
            <v-tabs v-model="tabs" align-with-title fixed-tabs>
              <v-tab style="margin: 0px"> Raster </v-tab>
              <v-tab
                :disabled="
                  Object.keys(vectorStatistics).length == 0 || route != 'raster'
                "
                v-if="route == 'raster'"
              >
                Vector
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabs">
              <v-tab-item>
                <v-list-item>
                  <v-list-item-content>
                    <h3>{{ active[0]["name"] }}</h3>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content v-if="active[0].hasOwnProperty('id')">
                    {{
                      "Nuts-Level:" + (active[0]["id"].length - 2).toString()
                    }}
                  </v-list-item-content>
                  <v-list-item-content>
                    {{
                      active[0]
                        ? "Nuts-ID: " + active[0]["id"]
                        : "Segment-ID: " + segmentID
                    }}
                  </v-list-item-content>
                  <v-list-item-content>
                    <div v-if="active[0]">
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            @click="generateReport()"
                            ><v-icon>mdi-download</v-icon></v-btn
                          >
                        </template>
                        <span>Download report</span>
                      </v-tooltip>
                    </div>
                  </v-list-item-content>
                </v-list-item>

                <pie-chart
                  id="piechart"
                  :data="chartData"
                  :options="chartOptions"
                />

                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th style="padding: 0px; width: auto"></th>
                        <th class="text-left">Name</th>
                        <th class="text-left">Area (km²)</th>
                        <th class="text-left">Percentage (%)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in statistics" :key="item.name">
                        <td
                          style="padding: 0px; width: auto; padding-left: 3px"
                        >
                          <i class="colorcircle" :style="item.color"></i>
                        </td>
                        <td>{{ item.name }}</td>
                        <td>{{ item.area.toFixed(2) }}</td>
                        <td>{{ item.percentage.toFixed(2) }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-tab-item>
              <v-tab-item>
                <v-list-item>
                  <v-list-item-content>
                    <h3>{{ active[0]["name"] }}</h3>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content v-if="active[0].hasOwnProperty('id')">
                    {{
                      "Nuts-Level:" + (active[0]["id"].length - 2).toString()
                    }}
                  </v-list-item-content>
                  <v-list-item-content>
                    Nuts-ID: {{ active[0]["id"] }}
                  </v-list-item-content>
                  <v-list-item-content>
                    <div v-if="active[0]">
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            @click="generateVectorReport()"
                            ><v-icon>mdi-download</v-icon></v-btn
                          >
                        </template>
                        <span>Download report</span>
                      </v-tooltip>
                    </div>
                  </v-list-item-content>
                </v-list-item>

                <pie-chart
                  id="piechart2"
                  :data="vectorChartData"
                  :options="chartOptions"
                />

                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th style="padding: 0px; width: auto"></th>
                        <th class="text-left">Name</th>
                        <th class="text-left">Area (km²)</th>
                        <th class="text-left">Percentage (%)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in vectorStatistics['Vector Landcover']"
                        :key="item.name"
                      >
                        <td
                          style="padding: 0px; width: auto; padding-left: 3px"
                        >
                          <i class="colorcircle" :style="item.color"></i>
                        </td>
                        <td>{{ item.name }}</td>
                        <td>{{ item.area.toFixed(2) }}</td>
                        <td>{{ item.percentage.toFixed(2) }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <v-divider style="margin-bottom: 30px"></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <h4>Other Statistics:</h4>
                  </v-list-item-content>
                </v-list-item>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Name</th>
                        <th class="text-left">Percentage (%)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(value, key) in vectorStatistics[
                          'Other Landcover'
                        ]"
                        :key="key"
                      >
                        <td>{{ key }}</td>
                        <td>{{ value.toFixed(2) }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-tab-item>
            </v-tabs-items>
          </div>
          <div v-else-if="segmentID">
            <v-list-item>
              <v-list-item-content>
                <h3>Segment-ID: {{ segmentID }}</h3>
              </v-list-item-content>
            </v-list-item>
            <div v-if="segment == 'vectorproduct'">
            <v-list-item>
              <v-list-item-content v-if="statistics.classcolor">
                <h4>
                  Landcover-Class: {{ statistics.Landcover
                  }}<i class="colorcircle" :style="statistics.classcolor"></i>
                </h4>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content v-if="statistics['Area']">
                <h4>Segment-Area(m²): {{ statistics["Area"].toFixed(2) }}</h4>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-content v-if="statistics['Area']">
                <h4>Raster Statistics:</h4>
              </v-list-item-content>
            </v-list-item>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Name</th>
                    <th class="text-left">Percentage (%)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(value, key) in statistics['Raster Landcover']"
                    :key="key"
                  >
                    <td>{{ key }}</td>
                    <td>{{ value.toFixed(2) }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-divider style="margin-bottom: 30px"></v-divider>
            <v-list-item>
              <v-list-item-content>
                <h4>Other Statistics:</h4>
              </v-list-item-content>
            </v-list-item>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Name</th>
                    <th class="text-left">Percentage (%)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(value, key) in statistics['Other Landcover']"
                    :key="key"
                  >
                    <td>{{ key }}</td>
                    <td>{{ value.toFixed(2) }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            </div>
          
          <div v-else>
            <v-list-item>
              <v-list-item-content v-if="statistics['Landcover']">
                <p>Landcover: {{ statistics["Landcover"] }}</p>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content v-if="statistics['Area (ha)']">
                <p>Area (ha): {{ statistics["Area (ha)"].toFixed(5) }}</p>
              </v-list-item-content>
            </v-list-item>
          </div>
          </div>
          <v-divider></v-divider>
        </div>
      </v-navigation-drawer>

      <Map
        v-bind:style="mapStyle"
        :bounds="bounds"
        :nuts_id="nuts_id"
        :searchFlag="searchFlag"
        @clickOnNuts="setSearch"
        @clickOnSegment="showSegment"
        @clickOnLayer="setSearchLayer"
        :legendData="legendData"
        ref="map"
      />
    </v-main>
  </v-app>
</template>

<script>
import Map from "./components/Map.vue";
import axios_services from "./axios/axios-services.js";
import PieChart from "./components/PieChart.vue";

export default {
  name: "App",

  components: {
    Map,
    PieChart,
  },

  data() {
    return {
      tabs: null,
      dialog: true,
      reportDialog: false,
      drawer: true,
      drawer2: 0,
      mini: true,
      mapStyle: "",
      search: null,
      active: [{}],
      open: [],
      searchFlag: false,
      clicked: false,
      statistics: {},
      route: null,
      segment: null,
      //Please remove vector_statistics and merge it with statistics
      vectorStatistics: {},
      chartOptions: {
        legend: {
          display: false,
        },
      },
      segmentID: null,
      chartData: {},
      vectorChartData: {},
      legendData: {
        raster: {
          1: { name: "Sealed", color: "#FF0000" },
          2: { name: "Woody - needle leaved trees", color: "#228B22" },
          3: { name: "Woody - Broadleaved deciduous trees", color: "#80FF00" },
          4: { name: "Woody - Broadleaved evergreen trees", color: "#00FF08" },
          5: {
            name: "Low-growing woody plants (bushes, shrubs)",
            color: "#804000",
          },
          6: { name: "Permanent herbaceous", color: "#CCF24D" },
          7: { name: "Periodically herbaceous", color: "#FFFF80" },
          8: { name: "Lichens and mosses", color: "#FF80FF" },
          9: { name: "Non- and sparsely-vegetated", color: "#BFBFBF" },
          10: { name: "Water", color: "#0080FF" },
          11: { name: "Snow and ice", color: "#00FFFF" },
        },
        vectorproduct: {
          11: { name: "Very High Sealing Degree", color: "#E6004D" },
          12: { name: "High Sealing Degree", color: "#FF0000" },
          21: { name: "Pure needle leaved", color: "#00A650" },
          22: { name: "Dominantly needle leaved", color: "#00C150" },
          31: { name: "Pure broadleaved deciduous", color: "#6DD400" },
          32: { name: "Pure broadleaved evergreen", color: "#4F9A00" },
          33: { name: "Dominantly broad leaved", color: "#80FF00" },
          40: { name: "Shrubland", color: "#A6F200" },
          51: { name: "Permanent herbaceous without trees", color: "#BABB4D" },
          52: { name: "Permanent herbaceous with few trees", color: "#D3D44D" },
          53: {
            name: "Permanent herbaceous with many trees",
            color: "#E6E64D",
          },
          60: { name: "Periodically herbaceous", color: "#FFFFA8" },
          70: { name: "Lichens and Mosses", color: "#A6A6FF" },
          81: {
            name: "Partly vegetated land - Low vegetation cover",
            color: "#CCFFCC",
          },
          82: {
            name: "Partly vegetated land - Intermediate vegetation cover",
            color: "#93FF93",
          },
          90: { name: "Non-vegetated land", color: "#CCCCCC" },
          100: { name: "Water", color: "#80F2E6" },
          110: { name: "Snow and Ice", color: "#A6E6CC" },
        },
        natura2k_vector: {
          1110: {
            name: "Urban fabric (predominantly public and private units) ",
            color: "#c00000",
          },
          1120: {
            name: "Industrial, commercial and military units",
            color: "#cc4df2",
          },
          1210: { name: "Road networks and associated land", color: "#969696" },
          1220: { name: "Railways and associated land", color: "#595959" },
          1230: { name: "Port areas and associated land", color: "#e6cccc" },
          1240: { name: "Airports and associated land", color: "#e6cce6" },
          1310: {
            name: "Mineral extraction, dump and construction sites",
            color: "#734d37",
          },
          1320: { name: "Land without current use", color: "#874545" },
          1400: {
            name: "Green urban, sports and leisure facilities",
            color: "#8cdc00",
          },
          2110: {
            name: "Arable irrigated and non-irrigated land",
            color: "#ffffa8",
          },
          2120: { name: "Greenhouses", color: "#bebe64" },
          2210: {
            name: "Vineyards, fruit trees and berry plantations",
            color: "#e07d01",
          },
          2220: { name: "Olive groves", color: "#df9f00" },
          2310: {
            name: "Annual crops associated with permanent crops",
            color: "#ffe6a6",
          },
          2320: { name: "Complex cultivation patterns", color: "#ffe64d" },
          2330: {
            name: "Land principally occupied by agriculture with significant areas of natural vegetation",
            color: "#e6cc4d",
          },
          2340: { name: "Agro-forestry ", color: "#edc69f" },
          3110: {
            name: "Natural & semi-natural broadleaved forest",
            color: "#96cd00",
          },
          3120: {
            name: "Highly artificial broadleaved plantations",
            color: "#82c800",
          },
          3210: {
            name: "Natural & semi natural coniferous forest",
            color: "#007300",
          },
          3220: {
            name: "Highly artificial coniferous plantations",
            color: "#006400",
          },
          3310: {
            name: "Natural & semi natural mixed forest",
            color: "#41af00",
          },
          3320: {
            name: "Highly artificial mixed plantations",
            color: "#007d00",
          },
          3400: { name: "Transitional woodland and scrub", color: "#a6f200" },
          3500: { name: "Lines of trees and scrub", color: "#c3f200" },
          3600: { name: "Damaged forest", color: "#003c00" },
          4110: { name: "Managed grassland", color: "#e6e64d" },
          4211: {
            name: "Semi-natural grassland with woody plants (C.C.D. ≥ 30 %)",
            color: "#e0f24d",
          },
          4212: {
            name: "Semi-natural grassland without woody plants (C.C.D. < 30%)",
            color: "#e0e84d",
          },
          4220: {
            name: "Alpine and sub-alpine natural grassland ",
            color: "#ccf24d",
          },
          5100: { name: "Heathland and moorland", color: "#a6ff80" },
          5200: { name: "Alpine scrub land", color: "#96eb76" },
          5300: { name: "Sclerophyllous scrubs", color: "#a6e64d" },
          6100: { name: "Sparsely vegetated areas", color: "#ccffcc" },
          6210: { name: "Beaches and dunes", color: "#dcf5dc" },
          6220: { name: "River banks", color: "#f0f5dc" },
          6310: { name: "Bare rocks, outcrops, cliffs", color: "#c8d2c8" },
          6320: { name: "Burnt areas (except burnt forest)", color: "#b4beb4" },
          6330: { name: "Glaciers and perpetual snow", color: "#a0aaa0" },
          7110: { name: "Inland marshes ", color: "#a6a6ff" },
          7121: { name: "Exploited peat bogs", color: "#4d4dff" },
          7122: { name: "Unexploited peat bogs", color: "#646ee6" },
          7210: { name: "Salt marshes ", color: "#91c8f0" },
          7220: { name: "Salines", color: "#64a0c8" },
          7230: { name: "Intertidal flats", color: "#a6a6e6" },
          8110: {
            name: "Natural & semi-natural water courses",
            color: "#00ccf0",
          },
          8120: {
            name: "Highly modified water courses and canals",
            color: "#00ccc8",
          },
          8130: {
            name: "Seasonally connected water courses (oxbows)",
            color: "#41dceb",
          },
          8210: { name: "Natural lakes", color: "#80f2e6" },
          8220: { name: "Reservoirs", color: "#80f5f0" },
          8230: { name: "Aquaculture ponds", color: "#80defa" },
          8240: {
            name: "Standing water bodies of extractive industrial sites",
            color: "#7dfafa",
          },
          8310: { name: "Lagoons", color: "#00ffa6" },
          8320: { name: "Estuaries", color: "#00ff87" },
          8330: { name: "Marine inlets and fjords", color: "#00c878" },
          8410: { name: "Open sea", color: "#e6f2ff" },
          8420: { name: "Coastal waters", color: "#d2e6eb" },
        },
        imd_raster: {
        0: {name: "All non-impervious areas", color: "#F0F0F0"},
        1: {name: "1% imperviousness value", color: "#FFEDC3"},
        2: {name: "2% to 49% imperviousness value  - color shades in between"},
        50: {name: "50% imperviousness value", color: "#AF4A33"},
        51: {name: "51% to 99% imperviousness value - color shades in between",},
        100: {name: "All non-impervious areas", color: "#999999"},
        254: {name: "Unclassifiable", color: "#000000"},
      },
      water_wetness: {
        0: {name: "Dry", color: "#FFFFFF"},
        1: {name: "Permanent water", color: "#004DA8"},
        2: {name: "Temporary water", color: "#0070FF"},
        3: {name: "Permanent wet", color: "#00C5FF"},
        4: {name: "Temporary wet", color: "#73FFDF"},
        253: {name: "Sea water", color: "#FFEDC3"},
        254: {name: "Unclassifiable", color: "#999999"},
      }
      },
    };
  },
  watch: {
    drawer2(newValue, oldValue) {
      if (newValue == 0) {
        this.mapStyle = "left: 56px; width: calc(100% - 56px);";
        setTimeout(this.$refs.map.invalidateMapSize, 10);
      } else if (oldValue == 0 || newValue == 0) {
        this.mapStyle = "left: calc(20% + 56px); width: calc(80% - 56px);";
        if (this.bounds) {
          setTimeout(this.$refs.map.updateBounds, 10);
        }
      }
    },
    mini() {
      this.mapStyle = "width: calc(100% - 56px);";
    },
    search(val) {
      if (!val) {
        this.open = [];
        return;
      }
      var length = this.search["id"].length - 2;
      for (var i in this.nuts) {
        var nuts = this.nuts[i];
        if (this.search["id"].includes(nuts["id"])) {
          this.open.push(nuts);
          if (length == 0) {
            this.active = [nuts];
            return;
          }
          for (var j in nuts["children"]) {
            if (this.search["id"].includes(nuts["children"][j]["id"])) {
              nuts = nuts["children"][j];
              this.open.push(nuts);
              if (length == 1) {
                this.active = [nuts];
                return;
              }
              for (var k in nuts["children"]) {
                if (this.search["id"].includes(nuts["children"][k]["id"])) {
                  nuts = nuts["children"][k];
                  this.open.push(nuts);
                  if (length == 2) {
                    this.active = [nuts];
                    return;
                  }
                  for (var l in nuts["children"]) {
                    if (this.search["id"].includes(nuts["children"][l]["id"])) {
                      nuts = nuts["children"][l];
                      this.active = [nuts];
                      return;
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
  },
  methods: {
    logout() {
      this.$keycloak.logout();
    },
    clickDrawer(value) {
      if (this.drawer2 == value) {
        this.drawer2 = 0;
      } else {
        this.drawer2 = value;
      }
    },
    getActiveValue(value) {
      this.tabs = 0;
      if (Object.keys(value).length != 0) {
        if (this.clicked) {
          this.searchFlag = false;
          this.clicked = false;
        } else {
          this.searchFlag = true;
        }
        this.active = value;
        this.getRasterStatistics(this.active[0]["id"]);
        this.vectorStatistics = {};
        if(this.route == "raster") {
          this.getVectorStatistics(this.active[0]["id"]);
        }
      }
      this.$refs.map.invalidateMapSize();
    },
    getOpenValue(value) {
      if (!value) {
        this.open = [];
      } else {
        this.open = value;
      }
    },
    setSearch(value, route) {
      this.route = route;
      this.tabs = 0;
      this.clicked = true;
      this.segmentID = null;
      for (var i in this.search_json) {
        if (this.search_json[i]["id"] == value) {
          this.search = this.search_json[i];
        }
      }
    },
    setSearchLayer(route) {
      this.route = route;
      this.getRasterStatistics(this.active[0]["id"])
    },
    showSegment(value, layer_name, segment) {
      console.log("SEGMENT:")
      console.log(segment)
      this.segmentID = value;
      this.segment = segment;
      this.getSegmentStatistics(value);
      this.search = null;
      this.active = [{}];
    },
    getRasterStatistics(id) {
      if (this.route != null && id != null) {
      this.drawer2 = 2;
      return axios_services
        .post("statistics/" + this.route, { nuts_id: id })
        .then((response) => {
          var statistics = response.data[this.route + "_statistics"];
          for (var element in statistics) {
            var item = statistics[element];
            if (item.percentage < 0.01) {
              delete statistics[element];
            }
          }
          if (this.route == "raster" || this.route == "water_wetness") {
            var percentages = [];
            var classColors = [];
            var names = [];
            for (item in statistics) {
              percentages.push(
                parseFloat(statistics[item]["percentage"].toFixed(2))
              );
              classColors.push(this.legendData[this.route][item].color != "#FFFFFF" ? this.legendData[this.route][item].color : "#EEEEEE");
              statistics[item]["color"] =
                "background: " + this.legendData[this.route][item].color;
              names.push(statistics[item]["name"]);
            }
            this.statistics = statistics;
            var index = percentages;
            index = Array.from(Array(index.length).keys()).sort((a, b) =>
              index[a] > index[b] ? -1 : (index[b] > index[a]) | 0
            );
            var tmp_percentages = [...percentages];
            var tmp_names = [...names];
            var tmp_classColors = [...classColors];
            for (var i = 0; i < percentages.length; i++) {
              percentages[i] = tmp_percentages[index[i]];
              names[i] = tmp_names[index[i]];
              classColors[i] = tmp_classColors[index[i]];
            }
            this.chartData = {
              labels: names,
              datasets: [
                {
                  backgroundColor: classColors,
                  data: percentages,
                },
              ],
            };
          } else {
            percentages = [];
            names = [];
            for (item in statistics) {
              percentages.push(
                parseFloat(statistics[item]["percentage"].toFixed(2))
              );
              names.push(statistics[item]["name"]);
            }
            this.statistics = statistics;
            this.chartData = {
              labels: names,
              datasets: [
                {
                  data: percentages,
                },
              ],
            };
          }
        })
        .catch((error) => {
          console.log("Error: Statistics data could not be loaded", error);
        }); }
        else {
          this.statistics = {};
        }
    },
    getVectorStatistics(id) {
      return axios_services
        .post("statistics/vector", { nuts_id: id })
        .then((response) => {
          var statistics =
            response.data["vector_statistics"]["Vector Landcover"];
          var percentages = [];
          var classColors = [];
          var names = [];
          for (var item in statistics) {
            percentages.push(
              parseFloat(statistics[item]["percentage"].toFixed(2))
            );
            classColors.push(this.legendData.vectorproduct[item].color);
            statistics[item]["color"] =
              "background: " + this.legendData.vectorproduct[item].color;
            names.push(statistics[item]["name"]);
          }
          this.vectorStatistics["Vector Landcover"] = statistics;
          var index = percentages;
          index = Array.from(Array(index.length).keys()).sort((a, b) =>
            index[a] > index[b] ? -1 : (index[b] > index[a]) | 0
          );
          var tmp_percentages = [...percentages];
          var tmp_names = [...names];
          var tmp_classColors = [...classColors];
          for (var i = 0; i < percentages.length; i++) {
            percentages[i] = tmp_percentages[index[i]];
            names[i] = tmp_names[index[i]];
            classColors[i] = tmp_classColors[index[i]];
          }
          this.vectorChartData = {
            labels: names,
            datasets: [
              {
                backgroundColor: classColors,
                data: percentages,
              },
            ],
          };
          this.vectorStatistics["Other Landcover"] =
            response.data["vector_statistics"]["Other Landcover"];
          for (var key in this.vectorStatistics["Other Landcover"]) {
            var value = this.vectorStatistics["Other Landcover"][key];
            if (value < 0.01) {
              delete this.vectorStatistics["Other Landcover"][key];
            }
          }
        })
        .catch((error) => {
          console.log("Error: Statistics data could not be loaded", error);
        });
    },
    getSegmentStatistics(id) {
      if(this.segment == "vectorproduct") {
      axios_services
        .post("product/vector", { polygon_id: id })
        .then((response) => {
          this.statistics = response.data["vector_id_attributes"];
          for (var key in this.statistics["Raster Landcover"]) {
            var value = this.statistics["Raster Landcover"][key];
            if (value == 0) {
              delete this.statistics["Raster Landcover"][key];
            }
          }
          for (key in this.statistics["Other Landcover"]) {
            value = this.statistics["Other Landcover"][key];
            if (value == 0) {
              delete this.statistics["Other Landcover"][key];
            }
          }
          for (var element in this.legendData.vectorproduct) {
            var item = this.legendData.vectorproduct[element];
            if (item.name == this.statistics.Landcover) {
              this.statistics["classcolor"] =
                "margin-left: 5px; background: " + item.color;
              break;
            }
          }
        })
        .catch((error) => {
          console.log("Error: Statistics data could not be loaded", error);
        }); }
        else {
          axios_services
        .post("product/natura2000", { polygon_id: id })
        .then((response) => {
          this.statistics = response.data["natura2000_id_attributes"];
          for (var key in this.statistics) {
            var value = this.statistics[key];
            if (value == 0) {
              delete this.statistics[key];
            }
          }
        })
        .catch((error) => {
          console.log("Error: Statistics data could not be loaded", error);
        });}
        },
    async generateReport() {
      await this.$refs.map.zoomToBounds();
      this.reportDialog = true;
      this.$reportService
        .createReport(this.statistics, this.active[0])
        .then(() => {
          this.reportDialog = false;
        })
        .catch((err) => {
          console.log(err);
          this.showNotification(
            "danger",
            "Error!",
            "Report could not be created"
          );
        });
    },
    async generateVectorReport() {
      await this.$refs.map.zoomToBounds();
      this.reportDialog = true;
      var tabledata2 = {};
      var x = 0;
      for (var key in this.vectorStatistics["Other Landcover"]) {
        tabledata2[x++] = {
          name: key,
          percentage: this.vectorStatistics["Other Landcover"][key],
        };
      }
      this.$vector_reportService
        .createReport(
          this.vectorStatistics["Vector Landcover"],
          this.active[0],
          tabledata2
        )
        .then(() => {
          this.reportDialog = false;
        })
        .catch((err) => {
          console.log(err);
          this.showNotification(
            "danger",
            "Error!",
            "Report could not be created"
          );
        });
    },
  },
  computed: {
    nuts() {
      var nuts = require("./assets/nuts.json");
      return nuts;
    },
    search_json() {
      var search = require("./assets/search.json");
      return search["search"];
    },
    search_string() {
      if (!this.search) return null;
      return this.search["name"];
    },
    bounds() {
      if (this.active[0] && this.active[0]["extent"]) {
        return this.active[0]["extent"];
      }
      return null;
    },
    nuts_id() {
      if (this.active[0]) {
        return this.active[0]["id"];
      }
      return null;
    },
  },
  created() {
    this.mapStyle = "left: 56px; width: calc(100% - 56px);";
  },
};
</script>

<style>
/* width */
::-webkit-scrollbar {
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #e8eaf5;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #3f51b5;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.v-btn__content {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block !important;
  overflow: hidden;
}

.colorcircle {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  opacity: 1;
  border: 1px solid;
  border-color: #888888;
  display: inline-block;
}

.v-treeview-node__label {
  cursor: pointer;
}
</style>



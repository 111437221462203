
<script>

import { Doughnut } from "vue-chartjs";

export default {
    extends: Doughnut,

    props: { data: { type: Object }, options: {type: Object} },
    
    mounted() {
        this.renderChart(this.data, this.options);
    },

    watch: {
        data: function () {
            this.renderChart(this.data, this.options);
        },
    },
};

</script>
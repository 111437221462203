import Vue from "vue";

import { jsPDF } from "jspdf";
import "jspdf-autotable";
import domtoimage from "dom-to-image";
//import axios_services from "@/axios/axios-services.js";

import "@/assets/fonts/Roboto-Regular-normal.js";
import "@/assets/fonts/Roboto-Bold-normal.js";

// Header text

let vector_reportService = new Vue({
  methods: {
    createReport(tabledata, nutsdata, tabledata2) {
      return new Promise((resolve, reject) => {
        createReport(tabledata, nutsdata, tabledata2)
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
});

/**
 * This functin creates a PDF report for a product
 */
async function createReport(tabledata, nutsdata, tabledata2) {

  // Create pfd object
  const doc = new jsPDF({ orientation: "p", unit: "mm" });

  var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  pageWidth = pageWidth + 1;
  //var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();

  var yStart = 0;
  const margin = 20;
//  const spacingHeader = 2;
//  const d = 5;

  // ** Header ** 

  // Rectangle
  doc.setDrawColor(0);
  doc.setFillColor(128, 128, 128);
  doc.rect(margin, 15, pageWidth - 2 * margin, 25, "F");

  // Header text
  doc.setFont("roboto-bold");
  doc.setFontSize(22);
  doc.setTextColor(255, 255, 255);

  doc.text("CLC+ Backbone", pageWidth - margin - 5, 26, { align: "right" });
  doc.text("Vector-Data Report", pageWidth - margin - 5, 34, { align: "right" });




  yStart = 50;

  doc.setTextColor(0, 0, 0);
  doc.setFontSize(14);
  doc.text("NUTS-ID: " + nutsdata.id, margin, yStart);
  doc.text("NUTS-Name: " + nutsdata.name, pageWidth/2, yStart, { align: "center" });
  doc.text("NUTS-Level: " + (nutsdata.id.length-2).toString(), pageWidth - margin, yStart, { align: "right" });

  yStart = 60;

  let imgTagValuesMap = await createImgTag("map",426);
  let imgTagMap = imgTagValuesMap[0];
  let imgWidthMap = imgTagValuesMap[1];
  let imgHeightMap = imgTagValuesMap[2];

  let imgMap = await getDataFromUri2(imgTagMap);
  let widhtNew = pageWidth - 2 * margin;
  let dimensions = getImageDimensions(imgWidthMap, imgHeightMap, widhtNew);

  doc.addImage(imgMap, "jpg", margin-51.25, yStart, dimensions[0]*1.3, dimensions[1]*1.3);

  yStart = 190;

  let imgTagValuesChart = await createImgTag("piechart2",0);
  let imgTagChart = imgTagValuesChart[0];
  let imgWidthChart = imgTagValuesChart[1];
  let imgHeightChart = imgTagValuesChart[2];

  let imgChart = await getDataFromUri2(imgTagChart);
  let widhtNewChart = pageWidth/4;
  let dimensionsChart = getImageDimensions(imgWidthChart, imgHeightChart, widhtNewChart);

  doc.addImage(imgChart, "jpg", pageWidth*0.66, yStart+10, dimensionsChart[0], dimensionsChart[1],);

  doc.autoTable({
    columns: [
      { dataKey: "color", header: "Class Color"},
      { dataKey: "name", header: "Class Name" },
      { dataKey: "area", header: "Area [km²]" },
      { dataKey: "percentage", header: "Percentage [%]" },
    ],
    body: bodyRows(tabledata),
    startY: yStart,
    margin: { left: 20 },
    tableWidth: pageWidth*0.75 - 2 * margin,
    font: "roboto",
    styles: { cellPadding: 1.2, fontSize: 8 },
    headStyles: { fillColor: "#808080" },
    didParseCell: function(cell) {if (cell.section != "head" && cell.column.dataKey === "color") { { cell.cell.styles.fillColor = hexToRgb(cell.cell.text[0].replace("background: ", "")); cell.cell.text[0] = "" } } }
  });

  doc.autoTable({
    columns: [
      { dataKey: "name", header: "Name"},
      { dataKey: "percentage", header: "Percentage [%]" },
    ],
    body: bodyRows2(tabledata2),
    startY: 50,
    margin: { left: 20 },
    tableWidth: pageWidth*0.75 - 2 * margin,
    font: "roboto",
    styles: { cellPadding: 1.2, fontSize: 8 },
    headStyles: { fillColor: "#808080" },
  });

 



  doc.save("clcplus-report-" + nutsdata.name +".pdf");
}

function bodyRows(tableData) {
    var body = [];
    for (var item in tableData) {
      body.push({
        color: tableData[item].color,
        name: tableData[item].name,
        area: tableData[item].area.toFixed(2),
        percentage: tableData[item].percentage.toFixed(2),
      });
    }
    return body;
  }

  function bodyRows2(tableData) {
    var body = [];
    for (var item in tableData) {
      body.push({
        name: tableData[item].name,
        percentage: tableData[item].percentage.toFixed(2),
      });
    }
    return body;
  }

function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [
      parseInt(result[1], 16),
      parseInt(result[2], 16),
      parseInt(result[3], 16)
     ] : null;
  }

function createImgTag(element, offset) {
    var node = document.getElementById(element);

    return new Promise((resolve) => {
      const x = {
        width: node.offsetWidth+offset,
        height: node.offsetHeight,
      };

      domtoimage
        .toPng(node, x)
        .then(function(dataUrl) {
          var img = new Image();
          img.id = "temp-map";
          img.src = dataUrl;
          document.body.appendChild(img);
  
          resolve([img.src, node.offsetWidth+offset, node.offsetHeight]);
        })
        .catch(function(error) {
          console.error("Error:", error);
        });
    });
  }

function getDataFromUri2(url) {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = function() {
        var canvas = document.createElement("canvas");
  
        canvas.width = this.naturalWidth;
        canvas.height = this.naturalHeight;
  
        var ctx = canvas.getContext("2d");
        ctx.fillStyle = "#ffffff"; /// set white fill style
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        canvas.getContext("2d").drawImage(this, 0, 0);
  
        resolve(canvas.toDataURL("image/jpeg"));
      };
      img.onerror = reject;
      img.src = url;
    });
  }

function getImageDimensions(widthOriginal, heightOriginal, widhtNew) {
    const heightNew = (heightOriginal / widthOriginal) * widhtNew;
    return [widhtNew, heightNew];
  }

export default {
  install: function(Vue) {
    Vue.prototype.$vector_reportService = vector_reportService;
  },
};
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueLogger from 'vuejs-logger';
import * as Keycloak from 'keycloak-js';
import reportService from "@/reportservice.js";
import vector_reportService from "@/vector_reportservice.js";

Vue.use(VueLogger);
Vue.use(reportService);
Vue.use(vector_reportService);
Vue.config.productionTip = false

let initOptions = {
  url: process.env.VUE_APP_KEYCLOAK_URL, realm: process.env.VUE_APP_KEYCLOAK_REALM, clientId: 'vue', onLoad: 'login-required'
}

let keycloak = Keycloak(initOptions);
Vue.prototype.$keycloak = keycloak

keycloak.init({ onLoad: initOptions.onLoad, redirectUri: process.env.VUE_APP_REDIRECT_URL }).then((auth) => {
  if (!auth) {
    window.location.reload();
  } else {
    Vue.$log.info("Authenticated");

    new Vue({
      vuetify,
      el: '#app',
      render: h => h(App, { 
        props: { keycloak: keycloak } 
      })
    })
  }


//Token Refresh
  setInterval(() => {
    keycloak.updateToken(70).then((refreshed) => {
      if (refreshed) {
        Vue.$log.info('Token refreshed' + refreshed);
      } else {
        Vue.$log.warn('Token not refreshed, valid for '
          + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
      }
    }).catch(() => {
      Vue.$log.error('Failed to refresh token');
    });
  }, 6000)

}).catch(() => {
  Vue.$log.error("Authenticated Failed");
});

